import styled from 'styled-components'

export default styled.div`
  width: 100%;
  display: flex;
  height: 295px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  .screenshot-camera-container {
    width: 400px;
  }
  .card-detail-wrapper {
    width: 210px;
    .detail-content-container {
      padding-left: 20px;
      padding-right: 10px;
      background: white;
      height: calc(100% - 40px);
      .detail-row-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-width: 190px;
        .content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 15px 20px;
          height: 84px;
          .title {
            font-family: 'Prompt', sans-serif;
            font-weight: bold;
            font-size: 10px;
            line-height: 1;
            text-transform: uppercase;
            color: rgba(31, 45, 57, 0.5);
            margin-bottom: 6px;
          }
          .value {
            font-family: 'Prompt', sans-serif;
            font-weight: bold;
            font-size: 24px;
            color: ${(props) => props.theme.darkBackground};
            max-width: 180px;
          }
        }
        .total-violation-wrapper {
          display: flex;
          flex-direction: column;
          width: 100%;
          max-height: 85px;
          padding-top: 12px;
          padding-left: 20px;
          .title {
            font-family: 'Prompt', sans-serif;
            font-weight: bold;
            font-size: 10px;
            line-height: 1;
            text-transform: uppercase;
            color: rgba(31, 45, 57, 0.5);
          }
          .value {
            font-family: 'Prompt', sans-serif;
            font-weight: bold;
            font-size: 54px;
            color: ${(props) => props.theme.orangeFont};
            margin-top: -12px;
            text-align: right;
          }
        }
      }
    }
  }

  .camera-detail-container {
    display: flex;
    flex: 1;
    width: 100%;
    .graph-content-container {
      background: white;
      padding-left: 20px;
      height: calc(100% - 40px);
    }
  }
`
