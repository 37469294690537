/* eslint-disable multiline-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-spaces-and-tabs */
import React from 'react'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'
import * as PIXI from 'pixi.js'

import SkeletonLoading from '../SkeletonLoading/Loadable'
import DivideLine from '../DivideLine/Loadable'
import BarChartComponent from '../BarChartComponent/Loadable'
import CameraDetailScreenShot from '../CameraDetailScreenShot/Loadable'
import NumberBox from '../NumberBox/Loadable'
import CardWrapper from '../CardWrapper/Loadable'

import CameraDetailStyled from './styledComponent'

import MESSAGE from './message'
import { convertZoneDataToCoordinatesXY } from '../../utils'

class CameraDetailCard extends React.PureComponent {
  componentDidMount() {
    this.removePIXIConsole()
  }

  removePIXIConsole() {
    PIXI.utils.skipHello()
  }

  getMessage(property) {
    const msg = MESSAGE[this.props.language]
    return msg[property]
  }

  getMiddleContent(cameraId) {
    const { area_name } = this.props.data
    const title = this.props.oneArea ? this.getMessage('camera_id') : this.getMessage('area_header')
    const value = this.props.oneArea ? cameraId : area_name
    return (
      <div className="content text-style">
        <div className="title">{title}</div>
        <div className="value prevent-text-overflow">{value}</div>
      </div>
    )
  }

  getViolationHeader() {
    const title = this.props.oneArea ? this.getMessage('violation_header_onearea') : this.getMessage('violation_header')
    return <div className="title">{title}</div>
  }

  getDetailBox() {
    const { camera, violation_count } = this.props.data

    const footerContentClassName = this.props.oneArea ? ' total-violation-wrapper ' : ' content '

    return (
      <CardWrapper cardTitle={this.getMessage('detail_header')} className="card-detail-wrapper">
        <div className="detail-content-container">
          <div className="detail-row-container">
            <div className="content text-style">
              <div className="title">{this.getMessage('camera_name_header')}</div>
              <Tooltip title={camera.name} placement="right">
                <div className="value prevent-text-overflow">{camera.name}</div>
              </Tooltip>
            </div>
          </div>
          <DivideLine className="detail-divide" />
          <div className="detail-row-container">{this.getMiddleContent(camera.camera_id)}</div>
          <DivideLine className="detail-divide" />
          <div className="detail-row-container">
            <div className={footerContentClassName + 'text-style'}>
              {this.getViolationHeader()}
              {this.props.oneArea && <div className="value">{violation_count}</div>}
            </div>
            {!this.props.oneArea && <NumberBox text={violation_count} />}
          </div>
        </div>
      </CardWrapper>
    )
  }

  getGraphBox() {
    const data = this.props.data.activity_graph

    return (
      <CardWrapper cardTitle={this.getMessage('graph_header')}>
        <div className="graph-content-container">
          <BarChartComponent
            listBars={this.props.listBars}
            data={data}
            xLabel={this.getMessage('time_label')}
            yLabel={this.getMessage('count_label')}
            dropdownForGraph
            language={this.props.language}
          />
        </div>
      </CardWrapper>
    )
  }

  getCameraDetailCardContent() {
    const { camera } = this.props.data
    const { image } = camera
    const coordinates = convertZoneDataToCoordinatesXY(this.props.data.camera.zones)
    const listGridColors = this.props.data.camera.zones.map((zone) => zone.grid_color)
    return (
      <>
        <div className="screenshot-camera-container">
          <CameraDetailScreenShot
            cameraData={camera}
            coordinates={coordinates}
            listGridColors={listGridColors}
            imgResolutionWidth={camera.resolution_width}
            imgResolutionHeight={camera.resolution_height}
            imgSrc={image}
            containerWidth={400}
            containerHeight={295}
            showCameraStatusIcon
            showCameraStatuslabel
          />
        </div>
        <div className="camera-detail-container">
          {this.getDetailBox()}
          {this.getGraphBox()}
        </div>
      </>
    )
  }

  getContent() {
    const content = this.props.isLoading ? <SkeletonLoading /> : this.getCameraDetailCardContent()
    return content
  }

  render() {
    return <CameraDetailStyled className="camera-detail-card">{this.getContent()}</CameraDetailStyled>
  }
}

CameraDetailCard.defaultProps = {
  language: 'EN',
  isLoading: false
}

CameraDetailCard.propTypes = {
  data: PropTypes.shape({
    coordinates: PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          x: PropTypes.number,
          y: PropTypes.number
        })
      )
    ),
    imgSrc: PropTypes.string,
    cameraData: PropTypes.shape({
      cameraID: PropTypes.string,
      cameraNO: PropTypes.string,
      area: PropTypes.string,
      violation: PropTypes.number,
      status: PropTypes.string
    }),
    violationData: PropTypes.arrayOf(
      PropTypes.shape({
        labelPoint: PropTypes.string,
        uv: PropTypes.number,
        pv: PropTypes.number
      })
    )
  }),
  isLoading: PropTypes.bool,
  listBars: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      color: PropTypes.string
    })
  ),
  oneArea: PropTypes.bool
}

export default CameraDetailCard
