const MESSAGE = {
  EN: {
    detail_header: 'DETAILS',
    graph_header: 'ACTIVITY',
    camera_name_header: 'CAMERA NAME',
    area_header: 'AREA',
    camera_id: 'CAMERA ID',
    violation_header: 'VIOLATION',
    violation_header_onearea: 'TOTAL VIOLATION',
    time_label: 'Time',
    count_label: 'Count'
  },
  TH: {
    detail_header: 'รายละเอียด',
    graph_header: 'จำนวนครั้งในการฝ่าฝืน',
    camera_name_header: 'ชื่อกล้อง',
    area_header: 'พื้นที่',
    camera_id: 'ID กล้อง',
    violation_header: 'การฝ่าฝืน',
    violation_header_onearea: 'การฝ่าฝืนทั้งหมด',
    time_label: 'เวลา',
    count_label: 'จำนวน'
  }
}

export default MESSAGE
